@font-face {
	font-family: 'ToyotaType';
	font-weight: normal;
	src: url('./assets/ToyotaType-Regular.otf');
}

@font-face {
	font-family: 'ToyotaType';
	font-weight: bold;
	src: url('./assets/ToyotaType-Semibold.otf');
}

@mixin font-normal {
	font-weight: normal;
	font-size: 16px;
	font-family: 'ToyotaType', sans-serif;
	line-height: 28px;
}

@mixin font-title-small {
	font-size: 24px;
	font-family: 'ToyotaType', sans-serif;
	font-weight: bold;
}

body {
	font-size: 16px;
	@include font-normal();
	background-color: #eeeeee;
}

.logo {
	height: 60px;
	width: 60px;
	position: absolute;
	top: 30px;
	left: 30px;
}

#register-top-box {
	color: white;
	background-color: #282830;
	height: 45vh;
	position: relative;
	@media screen and (max-width: 900px) {
		height: 60vh;
	}
	.computer {
		position: absolute;
		height: 70vh;
		right: 0;
		top: -8vh;
		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
	.top-box-content {
		position: absolute;
		max-width: 670px;
		bottom: 50px;
		padding-left: 10vw;
		@media screen and (max-width: 900px) {
			max-width: 100%;
			bottom: 20px;
			padding: 20px;
		}
		.big-title {
			font-size: 60px;
			font-weight: bold;
			margin-bottom: 48px;
			line-height: 60px;
			@media screen and (max-width: 900px) {
				font-size: 32px;
				line-height: 24px;
				margin-bottom: 12px;
			}
		}
		.sub-title {
			@include font-title-small();
			margin-bottom: 30px;
			@media screen and (max-width: 900px) {
				margin-bottom: 12px;
			}
		}
	}
}
#register-bottom-box {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	@media screen and (max-width: 900px) {
		display: flex;
		flex-direction: column;
	}
}
#bottom {
	padding: 50px 10vw;
	width: 100%;
	box-sizing: border-box;
	@media screen and (max-width: 900px) {
		padding: 10px;
		width: 100%;
	}
	.sub-title {
		margin-bottom: 16px;
	}
	.bottom-box {
		background-color: white;
		padding: 48px;
		position: relative;
		box-shadow: 0px 2px 2px #00000029;
		border-radius: 3px;
	}
	form {
		display: flex;
		flex-direction: column;
	}
	label {
		display: block;
		opacity: 0.4;
		margin-top: 16px;
		display: flex;
		align-items: baseline;
		margin-bottom: 8px;
	}
	input {
		box-sizing: border-box;
		display: block;
		border: #c6c6c6 1px solid;
		width: 100%;
		padding: 12px;
		border-radius: 5px;
		&.disabled {
			background-color: #dbdbdb;
		}
	}
	select {
		box-sizing: border-box;
		display: block;
		border: #c6c6c6 1px solid;
		width: 100%;
		padding: 12px;
		border-radius: 5px;
		margin-top: 8px;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none; /* Remove default arrow */
		background-image: url(./assets/sort-down.svg);
		background-repeat: no-repeat;
		background-position: 98% 50%;
		background-size: 3%;
	}
	.checkbox {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.checkbox-label {
			opacity: 1;
			margin-top: 0;
		}
	}
	input[type='checkbox'] {
		display: inline-block;
		width: 16px;
		height: 16px;
		margin-right: 8px;
		margin-top: 3px;
	}
	input[type='checkbox']:checked {
		background-image: none;
	}
	.privacy-box {
		display: flex;
		margin-top: 8px;
		input {
			width: 24px;
			height: 24px;
			margin-top: 3px;
			flex-shrink: 0;
		}
		label {
			display: inline-block;
			margin-top: 0;
			opacity: 1;
		}
	}
	.eye {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		cursor: pointer;
	}
	.submit-button {
		@include font-title-small();
		color: white;
		padding: 8px 24px;
		background-color: #fd0d1b;
		border-radius: 5px;
		margin-top: 24px;
		align-self: center;
	}
	.error {
		color: #fd0d1b;
	}
}

.sub-title {
	@include font-title-small();
}

.success {
	text-align: center;
}
