@charset "utf-8";

/* CSS Document */

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
object,
code,
em,
span,
var,
legend,
button,
input,
textarea,
th,
td,
a,
img,
header,
footer,
nav,
aside,
audio,
datalist,
section {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
} /*清除内外边距*/
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-size: 100%;
} /*设置默认字体*/
:focus {
    outline: 0;
}
ul,
ol,
ul li,
ol li {
    list-style: none;
} /*重置列表*/
address,
caption,
cite,
code,
dfn,
var {
    font-style: normal;
    font-weight: normal;
}
form label {
    cursor: pointer;
}
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    outline: none;
}
textarea {
    resize: none;
}
input {
    vertical-align: middle;
}
img {
    border: 0;
} /*重置图片元素*/
table {
    border-collapse: collapse;
    border-spacing: 0;
    padding: 0;
} /*重置表格*/
.l {
    float: left;
}
.r {
    float: right;
}
button,
input[type="reset"],
input[type="button"],
input[type="submit"] {
    line-height: normal !important;
}
a {
    text-decoration: none;
}
a {
    color: #666;
}
a:hover {
    text-decoration: underline;
}
input {
    _filter: chroma(color=#000000);
}

.clearfix:after {
    clear: both;
    content: ".";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}
.clearfix:after {
    _zoom: 1;
}
.overflow {
    overflow: hidden;
    _zoom: 1;
}
.clear {
    clear: both;
    height: 0;
    font-size: 0;
    overflow: hidden;
}
